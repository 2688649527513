import React, { useRef } from "react";
import Card from "../../components/FeatureCard";
import Slider from "react-slick";
import icon from "../../assets/image/svg/storage.svg";
import icon2 from "../../assets/image/svg/node.svg";
import icon3 from "../../assets/image/svg/chat-45.svg";
// import icon4 from "../../assets/image/svg/chat-45-2.svg";
// import icon5 from "../../assets/image/svg/node.svg";



const Feature = ({className , ...rest}) => {
  const elSlider = useRef();
  const sliderSettings = {
    autoplay: true,
    centerMode: true,
    centerPadding: "17%",
    slidesToShow: 3,
    rtl: true,
    arrows: false,
    touchMove: true,
    infinite: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          centerPadding: "10px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          centerPadding: "80px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 850,
        settings: {
          centerPadding: "10%",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 570,
        settings: {
          centerPadding: "30px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  };
  const featureCards = [
    {
      image: icon,
      title: "יעילות ונוחות",
      text:
        "המערכת קלה לתפעול ותאפשר לך להסיק מסקנות, להפיק תועלות ולשפר פלאים את ביצועי העובדים והעסק.",
    },
    {
      image: icon2,
      title: "שיא הגמישות",
      text:
        "ProperTime גמישה מאוד ומתממשקת בקלות לכל מערכות השכר. היא גם יודעת לחשב משמרות, תוספות וכו’.",
    },
    {
      image: icon3,
      title: "תמיכה מעולה",
      text:
        "עם צוות תמיכה אנושי, שזמין לעזור לך להתמודד עם האתגרים ולפזר את הערפל.",
    },
    {
      image: icon,
      title: "יעילות ונוחות",
      text:
        "המערכת קלה לתפעול ותאפשר לך להסיק מסקנות, להפיק תועלות ולשפר פלאים את ביצועי העובדים והעסק.",
    },
    {
      image: icon2,
      title: "שיא הגמישות",
      text:
        "ProperTime גמישה מאוד ומתממשקת בקלות לכל מערכות השכר. היא גם יודעת לחשב משמרות, תוספות וכו’.",
    },
    {
      image: icon3,
      title: "תמיכה מעולה",
      text:
        "עם צוות תמיכה אנושי, שזמין לעזור לך להתמודד עם האתגרים ולפזר את הערפל.",
    },
  ];

  return (
    <div
      className={className}
      {...rest}
    >
      {/* feature section */}
      <div className="container">
        {/* Section Title */}
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7 col-md-9">
            <div
              className="text-center mb-8 mb-lg-14"
              data-aos="zoom-in"
              data-aos-delay={500}
            >
              <h2 className="font-size-10 mb-6 letter-spacing-n83">
                מערכת שתתאים לך באופן מושלם
              </h2>
              <p className="font-size-6 mb-0">
                מגוון תכונות מאפשר לנו לתת מענה איכותי לארגונים שונים ועם תמיכה מעולה שיודעת לבחור את הפתרון המדויק עבורך
              </p>
            </div>
          </div>
        </div>
        {/* End Section Title */}
      </div>
      {/* feature slider */}
      <div className="row">
        <div className="col-12" data-aos="zoom-in" data-aos-delay={800}>
          {/* <div className="">
            </div> */}
          <Slider
            ref={elSlider}
            {...sliderSettings}
            className="l3-feature-slider d-flex"
          >
            {featureCards.map(({ image, title, text }, index) => {
              return <Card key={index} image={image} title={title} text={text} />;
            })}
          </Slider>
        </div>
      </div>
      {/* End feature slider */}
      {/*<div className="row justify-content-center">*/}
      {/*  <div className="col-12">*/}
      {/*    <div*/}
      {/*      className="mt-10 mt-lg-12 text-center"*/}
      {/*      data-aos="zoom-in"*/}
      {/*      data-aos-delay={1000}*/}
      {/*    >*/}
      {/*      <a*/}
      {/*        className="video-btn btn btn-blue-3 btn-2 rounded-5"*/}
      {/*        href="https://www.youtube.com/watch?v=LWZ7iytIA6k"*/}
      {/*      >*/}
      {/*        <i className="fa fa-play-circle mr-5" /> See Finity in Action*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default Feature;
