import React from "react";

const Contact = ({className, ...rest}) => {

  return (
    <div id='signup' className={`position-relative ${className}`} {...rest}
    >
      {/*<div*/}
      {/*  className="absolute-top-right"*/}
      {/*  data-aos="fade-left"*/}
      {/*  data-aos-delay={500}*/}
      {/*>*/}
      {/*  <img src={sectionShape} alt="" />*/}
      {/*</div>*/}
      <div className="container">
        {/* Section Title */}
        <div className="row justify-content-center">
          <div className="col-xl-12">
            <div
                className="mb-10 mb-lg-12 text-center dark-mode-texts"
                data-aos="fade-up"
                data-aos-duration={300}
                data-aos-once="true"
            >
              <h2 className="font-size-11 mb-7">רוצה לשמוע עוד?</h2>
              <p className="font-size-7 mb-0">
                צור קשר להתנסות 14 ימים ללא התחייבות
              </p>
            </div>
          </div>
        </div>
        {/* End Section Title */}
        {/* Contact Form */}
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="pt-12 pb-10 max-w-536 mx-auto">
              <form
                  data-aos="fade-up"
                  data-aos-duration={1100}
                  data-aos-once="true"
                  method="post"
                  id="signup-form"
                  name="signup-form"
                  action="/thank-you-for-sending-a-signup-request/"
                  data-netlify="true"
              >
                {/* First Name */}
                <div className="form-group mb-7 position-relative">
                  <input
                      type="text"
                      name="firstName"
                      className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                      placeholder="שם פרטי"
                      id="firstName"
                      required
                  />
                </div>
                {/* Last Name */}
                <div className="form-group mb-7 position-relative">
                  <input
                      type="text"
                      name="lastName"
                      className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                      placeholder="שם משפחה"
                      id="lastName"
                      required
                  />
                </div>
                {/* Email */}
                <div className="form-group mb-7 position-relative">
                  <input
                      type="email"
                      name="email"
                      className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                      style={{direction: 'ltr', textAlign: 'right'}}
                      placeholder="כתובת מייל"
                      id="email"
                      required
                  />
                </div>
                {/* Company Name */}
                <div className="form-group mb-7 position-relative">
                  <input
                      type="text"
                      name="companyName"
                      className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                      placeholder="שם החברה"
                      id="companyName"
                      required
                  />
                </div>
                {/* Phone Number */}
                <div className="form-group mb-7 position-relative">
                  <input
                      type="text"
                      name="phoneNumber"
                      className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                      placeholder="טלפון"
                      id="phoneNumber"
                      required
                  />
                </div>
                <input type="hidden" name="form-name" value="signup-form" />
                <div className="button">
                  <button type="submit" className="btn btn-blue-3 h-55 w-100 rounded-4"
                  >
                    צרו איתי קשר
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
